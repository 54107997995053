import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { bindActionCreators } from "redux"
import { callOrderViewGet } from "../../actions/orders"
import moment from "moment"

let BRL = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

let OrderView = ({ setPageAttr, methods: { callOrderViewGet }, view }) => {
    const params = useParams()
    const [id] = useState(!params.id ? '' : params.id)

    const descriptionPaymentMethod = (paymentMethod) => {
        switch (paymentMethod) {
            case 'pix':
                return 'PIX'
            case 'credit':
                return 'Cartão de Crédito'
            default:
                return '-'
        }
    }

    const descriptionStatus = (status) => {
        switch (status) {
            case 'paid':
                return <span className='btn btn-success btn-lg'>PAGO</span>
            case 'pending':
                return <span className='btn btn-warning btn-lg'>PENDENTE</span>
            case 'expired':
                return <span className='btn btn-danger btn-lg'>EXPIRADO</span>
            default:
                return <>-</>
        }
    }

    useEffect(() => {
        let tabs
        callOrderViewGet(id)
        tabs = [
            {
                active: false,
                to: '/orders',
                label: 'Informações'
            },
            {
                active: true,
                to: '/orders/view/' + id,
                label: 'Informações'
            },
        ]

        setPageAttr({
            title: 'Pedido #' + id,
            caption: 'Informações do pedido',
            btns: [],
            tabs: tabs
        })
    }, [setPageAttr, id, callOrderViewGet])

    return (
        <>
            <div className="form-row">
                <div className="col-md-12">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Rifa</th>
                                <th>Cliente</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{moment(view.created_at).format('DD/MM/YYYY HH:mm')}</td>
                                <td>{view.product_name}</td>
                                <td>{view.customer_name}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-md-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-title">Quantidade de Números:</div>
                            <div className="card-text">
                                <div className="btn btn-info btn-lg">{view.qty}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-title">Total:</div>
                            <div className="card-text">
                                <div className="btn btn-secondary btn-lg">{BRL.format(view.total)}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-title">Pagamento:</div>
                            <div className="card-text">
                                <div className="btn btn-secondary btn-lg">{descriptionPaymentMethod(view.payment_method)}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-title">Status:</div>
                            <div className="card-text">
                                {descriptionStatus(view.status)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix text-left mt-5">
                <button className="btn btn-primary" type="submit"><i className="mr-1 fas fa-save fa-white"></i>Salvar</button>
                <Link to={'/orders'} className="btn btn-secondary ml-3"><i className="fas fa-arrow-left mr-1"></i>Voltar</Link>
            </div>
        </>
    )
}

const mapStateToProps = ({ sale: { orders: { view } } }) => ({
    view: view,
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callOrderViewGet,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderView)