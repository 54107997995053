import { ACTION_ORDER_LIST, ACTION_ORDER_VIEW } from "../actions/orders";

let initialReducer = {
    orders: {
        list: {
            rows: [],
            total: 0,
            pagination: [],
        },
        view: {
            id: '',
            created_at: '',
            product_name: '',
            customer_name: '',
            qty: '',
            total: '',
            payment_method: '',
            status: '',
        },
    },
}

let SaleReducers = (state = initialReducer, action) => {
    switch (action.type) {
        case ACTION_ORDER_LIST.type:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    list: {
                        rows: action.payload.rows,
                        total: action.payload.total,
                        pagination: action.payload.pagination
                    },
                }
            };
        case ACTION_ORDER_VIEW.type:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    view: {
                        id: action.payload.id,
                        created_at: action.payload.created_at,
                        product_name: action.payload.product_name,
                        customer_name: action.payload.customer_name,
                        qty: action.payload.qty,
                        total: action.payload.total,
                        payment_method: action.payload.payment_method,
                        status: action.payload.status,
                    },
                },
            };
        default:
            return state;
    }
}

export default SaleReducers