import { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { bindActionCreators } from "redux"
import { callProductViewGet, callProductPost, callProductPut } from "../../actions/products"
import InputMask from "../../../../../common/containers/InputMask"
import { EDITOR_KEY } from "../../../../../constants"
import { Editor } from '@tinymce/tinymce-react';
import Helper from "../../../../../Helper"

let ProductEdit = ({ setPageAttr, methods: { callProductPost, callProductViewGet, callProductPut }, view }) => {
    const params = useParams()
    const [id, setId] = useState(!params.id ? '' : params.id)
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('')
    const [stock, setStock] = useState('')
    const [total, setTotal] = useState(0)

    const descriptionEditor = useRef()

    useEffect(() => {
        let tabs
        if (!id) {
            tabs = [
                {
                    active: false,
                    to: '/products',
                    label: 'Informações'
                },
                {
                    active: true,
                    to: '/products/add',
                    label: 'Adicionar'
                }
            ]
        } else {
            callProductViewGet(id)
            tabs = [
                {
                    active: false,
                    to: '/products',
                    label: 'Informações'
                },
                {
                    active: true,
                    to: '/products/edit/' + id,
                    label: 'Editar'
                },
                {
                    active: false,
                    to: '/products/images/' + id,
                    label: 'Imagens'
                }
            ]
        }

        setPageAttr({
            title: 'Rifa - ' + (!id ? 'Novo' : 'Editar'),
            caption: 'Preencha os campos para inserir as informações',
            btns: [],
            tabs: tabs
        })
    }, [setPageAttr, id, callProductViewGet])

    useEffect(() => {
        setName(view.name)
        setDescription(view.description)
        setPrice(view.price)
        setStock(view.stock)
    }, [view])

    useEffect(() => {
        let a = price ? Helper.numberToDecimal(price) : 0;
        let b = stock ? stock : 0;
        setTotal(a * b);
    }, [price, stock, setTotal])

    const finishedSubmit = (id = null) => {
        if (id) {
            setId(id)
            window.navigate('/products/edit/' + id)
            return;
        }
    }

    const submit = (e) => {
        e.preventDefault()
        let data = {
            name,
            description,
            price,
            stock
        }
        data = Helper.encodeProperty(data)

        if (!id) {
            callProductPost(data, (id) => finishedSubmit(id))
        } else {
            callProductPut(id, data, () => finishedSubmit())
        }
    }

    return (
        <form onSubmit={submit}>
            <div className="form-row">
                <div className="col-md-12">
                    <div className="form-row">
                        <div className="col-md-12 form-group">
                            <label className="required">Nome:</label>
                            <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} />
                        </div>
                        <div className="col-md-12 form-group">
                            <label>Regulamento/Descrição</label>
                            <Editor
                                apiKey={EDITOR_KEY}
                                onInit={(evt, editor) => descriptionEditor.current = editor}
                                value={description}
                                init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                    ],
                                    toolbar: 'undo redo | blocks | ' +
                                        'bold italic forecolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                                onEditorChange={(content, editor) => setDescription(content)}
                            />
                        </div>
                        <div className="col-md-4 form-group">
                            <label className="required text-right d-block">Preço/Número:</label>
                            <InputMask mask="dec_2" className="form-control text-right" value={price} onChange={v => setPrice(v)} />
                        </div>
                        <div className="col-md-4 form-group">
                            <label className="required text-right d-block">Qtde de Números:</label>
                            <InputMask mask="num" className="form-control text-right" value={stock} onChange={v => setStock(v)} />
                        </div>
                        <div className="col-md-4 form-group">
                            <label className="required text-right d-block">Valor a Receber:</label>
                            <div className="form-control text-right">{total.toLocaleString('pt-BR')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix text-left mt-3">
                <button className="btn btn-primary" type="submit"><i className="mr-1 fas fa-save fa-white"></i>Salvar</button>
                <Link to={'/products'} className="btn btn-secondary ml-3"><i className="fas fa-arrow-left mr-1"></i>Voltar</Link>
            </div>
        </form>
    )
}

const mapStateToProps = ({ product: { products: { view } } }) => ({
    view: view,
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callProductViewGet,
            callProductPost,
            callProductPut
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductEdit)