import { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { bindActionCreators } from "redux"
import { callConfigListGet, callConfigPut } from "../../actions/configs"
import Helper from "../../../../../Helper"
import InputMask from "../../../../../common/containers/InputMask"
import { EDITOR_KEY } from "../../../../../constants"
import { Editor } from '@tinymce/tinymce-react';

let ConfigEdit = ({ setPageAttr, methods: { callConfigListGet, callConfigPut }, view }) => {
    const [pgtoPixLimite, setPgtoPixLimite] = useState('')
    const [telefoneDuvidas, setTelefoneDuvidas] = useState('')
    const [termos, setTermos] = useState('')

    const termosEditor = useRef()

    useEffect(() => {
        callConfigListGet()
        let tabs = [
            {
                active: true,
                to: '/configs',
                label: 'Informações'
            }
        ]

        setPageAttr({
            title: 'Configurações',
            caption: 'Preencha os campos para alterar as informações',
            btns: [],
            tabs: tabs
        })
    }, [setPageAttr, callConfigListGet])


    const finishedSubmit = () => {
        window.navigate('/configs')
    }

    const submit = (e) => {
        e.preventDefault()
        let data = {
            pgto_pix_limite: pgtoPixLimite,
            telefone_duvidas: telefoneDuvidas,
            termos: termos
        }
        callConfigPut(Helper.encodeProperty(data), () => finishedSubmit())
    }

    useEffect(()=>{
        setPgtoPixLimite(view.pgto_pix_limite)
        setTelefoneDuvidas(view.telefone_duvidas)
        setTermos(view.termos)
    },[view])

    return (
        <form onSubmit={submit}>
            <div className="form-row">
                <div className="col-md-12">
                    <div className="form-row">
                        <div className="col-md-6 form-group">
                            <label className="required">Limite de valor para pagamento com PIX:</label>
                            <InputMask mask="phone" value={pgtoPixLimite} onChange={v => setPgtoPixLimite(v)} />
                        </div>
                        <div className="col-md-6 form-group">
                            <label className="required">Telefone de ajuda da página do detalhe da rifa:</label>
                            <InputMask mask="phone" value={telefoneDuvidas} onChange={v => setTelefoneDuvidas(v)} />
                        </div>
                        <div className="col-md-12 form-group">
                            <label className="required">Termos e condições da plataforma</label>
                            <Editor
                                apiKey={EDITOR_KEY}
                                onInit={(evt, editor) => termosEditor.current = editor}
                                value={termos}
                                init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                    ],
                                    toolbar: 'undo redo | blocks | ' +
                                        'bold italic forecolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                                onEditorChange={(content, editor) => setTermos(content)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix text-left mt-3">
                <button className="btn btn-primary" type="submit"><i className="mr-1 fas fa-save fa-white"></i>Salvar</button>
                <Link to={'/configs'} className="btn btn-secondary ml-3"><i className="fas fa-arrow-left mr-1"></i>Voltar</Link>
            </div>
        </form>
    )
}

const mapStateToProps = ({ system: { config } }) => ({
    view: config,
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callConfigPut,
            callConfigListGet,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigEdit)