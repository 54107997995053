import { toast } from "react-toastify";
import Api from '../../../../axios'

let ACTION_BANNER_LIST = {
    type: 'BANNER_LIST',
    payload: {
        rows: [],
        total: 0,
        pagination: [],
    },
};

let ACTION_BANNER_VIEW = {
    type: 'BANNER_VIEW',
    payload: {
        title: '',
        link: '',
        link_target: '',
        file_desktop: '',
        file_mobile: ''
    },
};

let callBannerListGet = (filter = {}, pg = 1) => (dispatch) => {
    const loader = toast.loading("Buscando registros...")
    Api.get('/banners?page=' + pg).then((data) => {
        if (!data) {
            toast.dismiss(loader)
            return
        }

        toast.dismiss(loader)
        ACTION_BANNER_LIST.payload.rows = data.rows
        ACTION_BANNER_LIST.payload.total = data.total
        ACTION_BANNER_LIST.payload.pagination = data.pagination
        dispatch(ACTION_BANNER_LIST)
    })
}

let callBannerViewGet = (id) => (dispatch) => {
    const loader = toast.loading("Buscando detalhes do registro...")
    Api.get('/banners/' + id).then((data) => {
        if (!data) {
            toast.dismiss(loader)
            return
        }

        toast.dismiss(loader)
        ACTION_BANNER_VIEW.payload.title = data.title
        ACTION_BANNER_VIEW.payload.link = data.link
        ACTION_BANNER_VIEW.payload.link_target = data.link_target
        ACTION_BANNER_VIEW.payload.file_desktop = data.file_desktop
        ACTION_BANNER_VIEW.payload.file_mobile = data.file_mobile
        dispatch(ACTION_BANNER_VIEW)
    })
}

let callBannerPost = (data, success = () => { }) => (dispatch) => {
    const loader = toast.loading("Cadastrando novo registro...")
    Api.post('/banners', data).then((data) => {
        if (!data) {
            toast.dismiss(loader)
            return
        }

        toast.update(loader, { render: data.msg, type: toast.TYPE.SUCCESS, isLoading: false, autoClose: 5000 })
        success()
    })
}

let callBannerPut = (id, data, success = () => { }) => (dispatch) => {
    const loader = toast.loading("Alterando registro...")
    Api.put('/banners/' + id + '', data).then((data) => {
        if (!data) {
            toast.dismiss(loader)
            return
        }

        toast.update(loader, { render: data.msg, type: toast.TYPE.SUCCESS, isLoading: false, autoClose: 5000 })
        success()
    })
}

let callBannerClearView = () => (dispatch) => {
    ACTION_BANNER_VIEW.payload.title = ''
    ACTION_BANNER_VIEW.payload.link = ''
    ACTION_BANNER_VIEW.payload.link_target = ''
    ACTION_BANNER_VIEW.payload.file_desktop = ''
    ACTION_BANNER_VIEW.payload.file_mobile = ''
    dispatch(ACTION_BANNER_VIEW)
}

let callBannerDelete = (id, success = () => { }) => (dispatch) => {
    const loader = toast.loading("Excluindo registro...")
    Api.delete('/banners/' + id).then((data) => {
        if (!data) {
            toast.dismiss(loader)
            return
        }

        toast.update(loader, { render: data.msg, type: toast.TYPE.SUCCESS, isLoading: false, autoClose: 5000 })
        success()
    })
}

export { callBannerListGet, callBannerViewGet, callBannerPost, callBannerPut, callBannerDelete, callBannerClearView, ACTION_BANNER_LIST, ACTION_BANNER_VIEW }