import { ACTION_BANNER_LIST, ACTION_BANNER_VIEW } from "../actions/banners";

let initialReducer = {
    banners: {
        list: {
            ...ACTION_BANNER_LIST.payload,
        },
        view: {
            ...ACTION_BANNER_VIEW.payload,
        },
    },
}

let TemplateReducer = (state = initialReducer, action) => {
    switch (action.type) {
        case ACTION_BANNER_LIST.type:
            return {
                ...state,
                banners: {
                    ...state.banners,
                    list: {
                        ...action.payload,
                    },
                }
            };
        case ACTION_BANNER_VIEW.type:
            return {
                ...state,
                banners: {
                    ...state.banners,
                    view: {
                        ...action.payload,
                    },
                },
            };
        default:
            return state;
    }
}

export default TemplateReducer