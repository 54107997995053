import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { bindActionCreators } from "redux"
import { callBannerViewGet, callBannerPost, callBannerPut } from "../../actions/banners"
import Helper from "../../../../../Helper"
import Resumable from 'resumablejs'
import { callLoader } from "../../../../../common/actions/app"
import { toast } from "react-toastify"

let BannerEdit = ({ setPageAttr, methods: { callBannerPost, callBannerViewGet, callBannerPut, callLoader }, view }) => {
    const params = useParams()
    const [id] = useState(!params.id ? '' : params.id)
    const [title, setTitle] = useState('')
    const [link, setLink] = useState('')
    const [linkTarget, setLinkTarget] = useState('')
    const [fileDesktop, setFileDesktop] = useState('')
    const [fileMobile, setFileMobile] = useState('')

    useEffect(() => {
        let tabs
        if (!id) {
            tabs = [
                {
                    active: false,
                    to: '/banners',
                    label: 'Informações'
                },
                {
                    active: true,
                    to: '/banners/add',
                    label: 'Adicionar'
                }
            ]
        } else {
            callBannerViewGet(id)
            tabs = [
                {
                    active: false,
                    to: '/banners',
                    label: 'Informações'
                },
                {
                    active: true,
                    to: '/banners/edit/' + id,
                    label: 'Editar'
                },
                {
                    active: false,
                    to: '/banners/orders/' + id,
                    label: 'Pedidos'
                }
            ]
        }

        setPageAttr({
            title: 'Banner - ' + (!id ? 'Novo' : 'Editar'),
            caption: 'Preencha os campos para inserir as informações',
            btns: [],
            tabs: tabs
        })
    }, [setPageAttr, id, callBannerViewGet])

    useEffect(() => {
        setTitle(view.title)
        setLink(view.link)
        setLinkTarget(view.link_target)
        setFileDesktop(view.file_desktop)
        setFileMobile(view.file_mobile)
    }, [view])

    const finishedSubmit = () => {
        setTitle('')
        window.navigate('/banners')
    }

    const submit = (e) => {
        e.preventDefault()

        let data = {
            title: title,
            link: link,
            link_target: linkTarget,
            file_desktop: fileDesktop,
            file_mobile: fileMobile
        }
        data = Helper.encodeProperty(data)

        if (!id) {
            callBannerPost(data, () => finishedSubmit())
        } else {
            callBannerPut(id, data, () => finishedSubmit())
        }
    }

    useEffect(() => {
        let resumable = new Resumable({
            target: `${process.env.REACT_APP_API_HOST}/banners/upload`,
            withCredentials: true,
            chunkSize: 1 * 1024 * 1024,
            simultaneousUploads: 1,
            testChunks: false,
            throttleProgressCallbacks: 1,
            maxFiles: 1,
            fileType: ['png', 'jpg', 'jpeg'],
        });
        resumable.assignBrowse(document.getElementById('btn-upload-desktop'));

        resumable.on('fileSuccess', function (file, res) {
            var json = JSON.parse(res);

            let responseCode = json?.response?.code
            let responseMsg = json?.response?.msg
            if (responseCode !== 0) {
                if (responseMsg) {
                    toast.error(responseMsg);
                    return;
                }

                toast.error('Erro ao fazer o upload da imagem!');
                return;
            }

            setFileDesktop(json?.data?.filename)
        });
        resumable.on('fileAdded', function (file, event) {
            callLoader(true)
            resumable.upload()
        });
        resumable.on('complete', function () {
            callLoader(false)
        });

    }, [setFileDesktop, callLoader])

    useEffect(() => {
        let resumable = new Resumable({
            target: `${process.env.REACT_APP_API_HOST}/banners/upload`,
            withCredentials: true,
            chunkSize: 1 * 1024 * 1024,
            simultaneousUploads: 1,
            testChunks: false,
            throttleProgressCallbacks: 1,
            maxFiles: 1,
            fileType: ['png', 'jpg', 'jpeg'],
        });
        resumable.assignBrowse(document.getElementById('btn-upload-mobile'));

        resumable.on('fileSuccess', function (file, res) {
            var json = JSON.parse(res);

            let responseCode = json?.response?.code
            let responseMsg = json?.response?.msg
            if (responseCode !== 0) {
                if (responseMsg) {
                    toast.error(responseMsg);
                    return;
                }

                toast.error('Erro ao fazer o upload da imagem!');
                return;
            }

            setFileMobile(json?.data?.filename)
        });
        resumable.on('fileAdded', function (file, event) {
            callLoader(true)
            resumable.upload()
        });
        resumable.on('complete', function () {
            callLoader(false)
        });

    }, [setFileMobile, callLoader])

    return (
        <form onSubmit={submit}>
            <div className="form-row">
                <div className="col-md-12 form-group">
                    <label className="required">Título:</label>
                    <input type="text" className="form-control" value={title} onChange={e => setTitle(e.target.value)} />
                </div>
                <div className="col-md-6 form-group">
                    <label className="required">Link:</label>
                    <input type="text" className="form-control" value={link} onChange={e => setLink(e.target.value)} />
                </div>
                <div className="col-md-6 form-group">
                    <label className="required">Target:</label>
                    <select className="form-control" value={linkTarget} onChange={e => setLinkTarget(e.target.value)}>
                        <option value="">Selecione...</option>
                        <option value="_self">Abrir na mesma página</option>
                        <option value="_blank">Abrir nova página</option>
                    </select>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-3">
                    <button className="btn btn-primary btn-block" id="btn-upload-desktop" type="button"><i className="mr-1 fas fa-plus fa-white"></i>Adicionar Imagem Desktop</button>
                    <div className="mt-3">
                        <img src={fileDesktop ? `${process.env.REACT_APP_API_HOST}/storage/banners/${fileDesktop}` : '/assets/img/image.jpg'} alt="Imagem Desktop" className="w-100" />
                    </div>
                </div>
                <div className="col-md-3">
                    <button className="btn btn-warning btn-block" id="btn-upload-mobile" type="button"><i className="mr-1 fas fa-plus fa-white"></i>Adicionar Imagem Mobile</button>
                    <div className="mt-3">
                        <img src={fileMobile ? `${process.env.REACT_APP_API_HOST}/storage/banners/${fileMobile}` : '/assets/img/image.jpg'} alt="Imagem Desktop" className="w-100" />
                    </div>
                </div>
            </div>
            <div className="clearfix text-left mt-3">
                <button className="btn btn-primary" type="submit"><i className="mr-1 fas fa-save fa-white"></i>Salvar</button>
                <Link to={'/banners'} className="btn btn-secondary ml-3"><i className="fas fa-arrow-left mr-1"></i>Voltar</Link>
            </div>
        </form>
    )
}

const mapStateToProps = ({ template: { banners: { view } } }) => ({
    view: view,
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callBannerViewGet,
            callBannerPost,
            callBannerPut,
            callLoader
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(BannerEdit)