import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import AppReducer from '../common/reducers';
import RegisterReducers from './modules/register/reducers';
import ProductReducers from './modules/product/reducers';
import TemplateReducer from './modules/template/reducers';
import SystemReducers from './modules/system/reducers';
import SaleReducers from './modules/sales/reducers';
import DashboardReducers from './modules/dashboard/reducers';

let reducers = combineReducers({
    app: AppReducer,
    register: RegisterReducers,
    product: ProductReducers,
    template: TemplateReducer,
    system: SystemReducers,
    sale: SaleReducers,
    dashboard: DashboardReducers
});
let store = createStore(reducers, applyMiddleware(thunk))
export default store