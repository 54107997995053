import { ACTION_CONFIG_LIST } from "../actions/configs";

let initialReducer = {
    config: {
        pgto_pix_limite: '',
        telefone_duvidas: '',
        termos: ''
    },
}

let SystemReducers = (state = initialReducer, action) => {
    switch (action.type) {
        case ACTION_CONFIG_LIST.type:
            return {
                ...state,
                config: {
                    pgto_pix_limite: action.payload.pgto_pix_limite,
                    telefone_duvidas: action.payload.telefone_duvidas,
                    termos: action.payload.termos,
                }
            };
        default:
            return state;
    }
}

export default SystemReducers