import { toast } from "react-toastify";
import Api from '../../../../axios'

let ACTION_DASHBOARD_VIEW = {
    type: 'DASHBOARD_VIEW',
    payload: {
        qtySaleByDays: [],
        totalSales: 0,
        qtySales: 0,
        qtyRafflesPending: 0,
        qtyRafflesFinished: 0,
        totalAllLife: 0,
        rafflesPending: [],
    },
};

let callDashboardViewGet = () => (dispatch) => {
    const loader = toast.loading("Buscando detalhes do registro...")
    Api.get('/dashboard').then((data) => {
        if (!data) {
            toast.dismiss(loader)
            return
        }

        toast.dismiss(loader)
        ACTION_DASHBOARD_VIEW.payload.qtySaleByDays = data.qtySaleByDays
        ACTION_DASHBOARD_VIEW.payload.totalSales = data.totalSales
        ACTION_DASHBOARD_VIEW.payload.qtySales = data.qtySales
        ACTION_DASHBOARD_VIEW.payload.qtyRafflesPending = data.qtyRafflesPending
        ACTION_DASHBOARD_VIEW.payload.qtyRafflesFinished = data.qtyRafflesFinished
        ACTION_DASHBOARD_VIEW.payload.totalAllLife = data.totalAllLife
        ACTION_DASHBOARD_VIEW.payload.rafflesPending = data.rafflesPending
        
        dispatch(ACTION_DASHBOARD_VIEW)
    })
}

export { callDashboardViewGet, ACTION_DASHBOARD_VIEW }