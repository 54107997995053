import { ACTION_DASHBOARD_VIEW } from "../actions/dashboard.";

let initialReducer = {
    qtySaleByDays: [],
    totalSales: 0,
    qtySales: 0,
    qtyRafflesPending: 0,
    qtyRafflesFinished: 0,
    totalAllLife: 0,
    rafflesPending: []
}

let DashboardReducers = (state = initialReducer, action) => {
    switch (action.type) {
        case ACTION_DASHBOARD_VIEW.type:
            return {
                ...state,
                qtySaleByDays: action.payload.qtySaleByDays,
                totalSales: action.payload.totalSales,
                qtySales: action.payload.qtySales,
                qtyRafflesPending: action.payload.qtyRafflesPending,
                qtyRafflesFinished: action.payload.qtyRafflesFinished,
                totalAllLife: action.payload.totalAllLife,
                rafflesPending: action.payload.rafflesPending
            };
        default:
            return state;
    }
}

export default DashboardReducers