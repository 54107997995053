import { connect } from 'react-redux'
import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { callProductListGet, callProductDelete, callProductClearView, callProductVisible} from '../../actions/products'
import { Link, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import TableView from '../../../../../common/containers/TableView'
import './../../styles/ProductList.css'

let ProductList = ({ setPageAttr, list, methods: { callProductListGet, callProductDelete, callProductClearView, callProductVisible } }) => {
    let [searchParams] = useSearchParams();
    const remove = (id) => {
        Swal.fire({
            title: 'Tem certeza que deseja deletar? Essa ação não poderá ser desfeita!',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                callProductDelete(id, () => {
                    let pg = searchParams.get('pg')
                    pg = pg ? pg : 1
                    callProductListGet({}, pg)
                })
            }
        })
    }

    const visible = (id) => {
        Swal.fire({
            title: 'Tem certeza que deseja alterar a visibilidade dessa rifa?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                callProductVisible(id, () => {
                    let pg = searchParams.get('pg')
                    pg = pg ? pg : 1
                    callProductListGet({}, pg)
                })
            }
        })
    }
    const headers = [
        {
            type: 'custom',
            align: 'center',
            style: {
                width: '100px'
            },
            label: 'Foto',
            custom: (a) => {
                if (!a.file) {
                    return <></>
                }
                return (
                    <>
                        <img src={`${process.env.REACT_APP_API_HOST}/storage/products/${a.file}`} className='img-thumb' alt='foto do produto' style={{ width: '70px' }} />
                    </>
                )
            }
        },
        {
            type: 'info',
            name: 'name',
            align: 'left',
            label: 'Nome'
        },
        {
            type: 'money',
            name: 'price',
            align: 'right',
            label: 'Preço/Números'
        },
        {
            type: 'info',
            name: 'stock',
            align: 'right',
            label: 'Disponíveis'
        },
        {
            type: 'info',
            name: 'sold',
            align: 'right',
            label: 'Vendidos'
        },
        {
            type: 'datetime',
            name: 'created_at',
            align: 'right',
            label: 'Cadastro'
        },
        {
            type: 'custom',
            align: 'right',
            label: 'Opções',
            custom: (a) => {
                return (
                    <>
                        {!a.visible ?
                            <button type='button' className='btn btn-sm btn-warning' title='Tornar invisível' onClick={_ => visible(a.id)}><i className='fas fa-eye-slash'></i></button> :
                            <button type='button' className='btn btn-sm btn-success' title='Tornar visível' onClick={_ => visible(a.id)}><i className='fas fa-eye text-white'></i></button>}
                        <Link to={'/products/edit/' + a.id} className='btn btn-sm btn-primary ml-2'><i className='fas fa-edit'></i></Link>
                        <span className='btn btn-sm btn-danger ml-2' onClick={_ => remove(a.id)}><i className='fas fa-trash'></i></span>
                    </>
                )
            }
        }
    ]

    useEffect(() => {
        callProductClearView()
        let pg = searchParams.get('pg')
        pg = pg ? pg : 1
        callProductListGet({}, pg)
    }, [callProductListGet, callProductClearView, searchParams])

    useEffect(() => {
        setPageAttr({
            title: 'Rifas',
            caption: 'Gestão das rifas',
            btns: [
                {
                    type: 'link',
                    class: 'btn btn-primary',
                    icon: 'fas fa-plus',
                    label: 'Adicionar',
                    to: '/products/add'
                }
            ],
            tabs: [
                {
                    active: true,
                    to: '/products',
                    label: 'Informações'
                }
            ]
        })
    }, [setPageAttr])

    return (
        <TableView headers={headers} rows={list.rows} total={list.total} pagination={list.pagination} paginationTo={'/products'} />
    )
}

const mapStateToProps = ({ product: { products: { list } } }) => ({
    list: list,
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callProductListGet,
            callProductDelete,
            callProductClearView,
            callProductVisible
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)