import { connect } from 'react-redux'
import { Route, Routes } from 'react-router'
import ConfigEdit from './ConfigEdit'
import { useState } from 'react'
import Sidebar from '../../../../../common/containers/Sidebar'
import Card from '../../../../../common/containers/Card'

let ConfigPage = () => {
    const [pageAttr, setPageAttr] = useState({})

    return (
        <Sidebar pWidth='60%'>
            <Card {...pageAttr} closeTo='/'>
                <Routes>
                    <Route index element={<ConfigEdit setPageAttr={setPageAttr} />} />
                </Routes>
            </Card>
        </Sidebar>
    )
}

export default connect()(ConfigPage)