import { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import { bindActionCreators } from "redux"
import { callProductImageListGet, callProductImagePost, callProductImageDelete, callProductImagePrincipal } from "../../actions/productImages"
import Swal from "sweetalert2"
import Resumable from 'resumablejs'
import { callLoader } from "../../../../../common/actions/app"
import { toast } from "react-toastify"

let ProductImage = ({ setPageAttr, methods: { callLoader, callProductImageListGet, callProductImagePost, callProductImageDelete, callProductImagePrincipal }, list }) => {
    const params = useParams()
    const [productId] = useState(!params.productId ? '' : params.productId)

    const remove = (id) => {
        Swal.fire({
            title: 'Tem certeza que deseja deletar? Essa ação não poderá ser desfeita!',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                callProductImageDelete(id, () => {
                    callProductImageListGet(productId)
                })
            }
        })
    }

    const active = (id) => {
        Swal.fire({
            title: 'Tem certeza que deseja tornar essa imagem principal?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                callProductImagePrincipal(id, () => {
                    callProductImageListGet(productId)
                })
            }
        })
    }

    useEffect(() => {
        callProductImageListGet(productId)
        let tabs = [
            {
                active: false,
                to: '/products',
                label: 'Informações'
            },
            {
                active: false,
                to: '/products/edit/' + productId,
                label: 'Editar'
            },
            {
                active: true,
                to: '/products/images/' + productId,
                label: 'Imagens'
            }
        ]

        setPageAttr({
            title: 'Rifa - Imagens',
            caption: 'Preencha os campos para inserir as informações',
            btns: [],
            tabs: tabs
        })
    }, [setPageAttr, productId, callProductImageListGet])

    const save = useCallback((filename)=>{
        let data = {
            productId,
            filename
        }

        callProductImagePost(data, () => {
            callProductImageListGet(productId)
        })
    },[productId, callProductImagePost, callProductImageListGet])

    useEffect(() => {
        let resumable = new Resumable({
            target: `${process.env.REACT_APP_API_HOST}/product-images/upload`,
            withCredentials: true,
            chunkSize: 1 * 1024 * 1024,
            simultaneousUploads: 1,
            testChunks: false,
            throttleProgressCallbacks: 1,
            maxFiles: 1,
            fileType: ['png', 'jpg', 'jpeg'],
        });
        resumable.assignBrowse(document.getElementById('btn-upload'));

        resumable.on('fileSuccess', function (file, res) {
            var json = JSON.parse(res);

            let responseCode = json?.response?.code
            let responseMsg = json?.response?.msg
            if(responseCode!==0){
                if(responseMsg){
                    toast.error(responseMsg);
                    return;
                }

                toast.error('Erro ao fazer o upload da imagem!');
                return;
            }
            
            save(json?.data?.filename)
        });
        resumable.on('fileAdded', function (file, event) {
            callLoader(true)
            resumable.upload()
        });
        resumable.on('complete', function () {
            callLoader(false)
        });

    }, [save, callLoader])

    return (
        <>
            <div className="clearfix text-left">
                <button className="btn btn-primary" id="btn-upload" type="button"><i className="mr-1 fas fa-plus fa-white"></i>Adicionar Imagem</button>
            </div>
            <div className={'row ' + (list.length > 0 ? 'mt-3' : '')}>
                {list.map((a, ai) => {
                    return (
                        <div key={ai} className="col-md-3">
                            <div>
                                <div style={{ height: '238px' }} className='d-flex align-items-center'>
                                    <img className='img-thumbnail' src={`${process.env.REACT_APP_API_HOST}/storage/products/${a.file}`} alt='foto do produto' style={{ maxHeight: '100%', width: '100%' }} />
                                </div>
                                <div className='d-flex mt-2'>

                                    <button className="btn btn-danger flex-fill" type="button" onClick={() => remove(a.id)}><i className="mr-1 fas fa-trash fa-white"></i>Deletar</button>
                                    {a.id !== a.image_id ? (
                                        <>
                                            <div style={{ width: '20px' }}></div>
                                            <button onClick={() => active(a.id)} className="btn btn-success flex-fill" type="button"><i className="mr-1 fas fa-check text-white"></i>Principal</button>
                                        </>
                                    ) : <></>}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

const mapStateToProps = ({ product: { productImages: { list } } }) => ({
    list: list,
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callLoader,
            callProductImageListGet,
            callProductImagePost,
            callProductImageDelete,
            callProductImagePrincipal
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductImage)