import { toast } from "react-toastify";
import Api from '../../../../axios'

let ACTION_CONFIG_LIST = {
    type: 'CONFIG_LIST',
    payload: {
        pgto_pix_limite: '',
        telefone_duvidas: '',
        termos: ''
    },
};

let callConfigListGet = (filter = {}) => (dispatch) => {
    const loader = toast.loading("Buscando registros...")
    Api.get('/configs').then((data) => {
        if (!data) {
            toast.dismiss(loader)
            return
        }
        ACTION_CONFIG_LIST.payload = {
            ...data
        }
        dispatch(ACTION_CONFIG_LIST)

        toast.dismiss(loader)
    })
}

let callConfigPut = (data, success = () => { }) => (dispatch) => {
    const loader = toast.loading("Alterando registro...")
    Api.put('/configs', data).then((data) => {
        if (!data) {
            toast.dismiss(loader)
            return
        }
        
        toast.update(loader, { render: data.msg, type: toast.TYPE.SUCCESS, isLoading: false, autoClose: 5000 })
        success()
    })
}

export { callConfigListGet, callConfigPut, ACTION_CONFIG_LIST }