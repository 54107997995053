import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { callDashboardViewGet } from "../actions/dashboard.";
import { useEffect } from "react";
import { Chart } from "react-google-charts";
import TableView from "../../../../common/containers/TableView";

let BRL = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

let Dashboard = ({ qtySaleByDays, totalSales, qtySales, qtyRafflesPending, qtyRafflesFinished, totalAllLife, rafflesPending, methods: { callDashboardViewGet } }) => {
    const optionsQtySaleByDays = {
        chart: {
            title: "Vendas por Dia",
            subtitle: "Dentro do mês",
        },
    };

    const diffDays = (d1, d2) => {
        let diff = d2.getTime()-d1.getTime()
        return Math.floor(diff / (1000 * 60 * 60 * 24));  
    }

    const myParseFloat = (str,val) => {
        str = str.toString();
        str = str.slice(0, (str.indexOf(".")) + val + 1); 
        return Number(str);   
    }

    useEffect(() => {
        callDashboardViewGet()
    }, [callDashboardViewGet])

    const headersRaffles = [
        {
            type: 'custom',
            align: 'center',
            style: {
                width: '100px'
            },
            label: 'Foto',
            custom: (a) => {
                if (!a.file) {
                    return <></>
                }
                return (
                    <>
                        <img src={`${process.env.REACT_APP_API_HOST}/storage/products/${a.file}`} className='img-thumb' alt='foto do produto' style={{ width: '70px' }} />
                    </>
                )
            }
        },
        {
            type: 'info',
            name: 'name',
            align: 'left',
            label: 'Nome'
        },
        {
            type: 'info',
            name: 'stock',
            align: 'center',
            label: 'Números'
        },
        {
            type: 'custom',
            align: 'center',
            label: 'Disponíveis',
            custom: (a) => {
                return a.stock-a.sold
            }
        },
        {
            type: 'info',
            name: 'sold',
            align: 'center',
            label: 'Vendidos'
        },
        {
            type: 'custom',
            align: 'center',
            label: 'Vendidos(%)',
            custom: (a) => {
                if(a.sold===0){
                    return '-'
                }
                return myParseFloat((a.stock-a.sold)/a.stock,2).toString().replace(/\./igm,',')+'%'
            }
        },
        {
            type: 'datetime',
            name: 'created_at',
            align: 'right',
            label: 'Cadastro'
        },
        {
            type: 'custom',
            align: 'center',
            label: 'Tempo(dias)',
            custom: (a) => {
                let d1 = new Date(a.created_at)
                let d2 = new Date()
                return diffDays(d1, d2)
            }
        },
        {
            type: 'custom',
            align: 'center',
            label: 'Acumulado',
            custom: (a) => {
                if(!a.total){
                    return BRL.format(0)
                }

                return BRL.format(a.total)
            }
        },
    ]

    return (
        <div className="container-fluid p-4">
            <div className="d-flex justify-content-between">
                <div>
                    <h2 className="card-title mb-0">Dashboard</h2>
                    <p className="card-text p-0 m-0">Resumo de vendas.</p>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-4 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Rifas em aberto:</h4>
                            <h5 className="card-text text-success">
                                {qtyRafflesPending}
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Rifas sorteadas:</h4>
                            <h5 className="card-text text-success">
                                {qtyRafflesFinished}
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Total Acumulado:</h4>
                            <h5 className="card-text text-success">
                                {BRL.format(totalAllLife)}
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Total em Vendas (Mês):</h4>
                            <h5 className="card-text text-success">
                                {BRL.format(totalSales)}
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Qtde em Vendas (Mês):</h4>
                            <h5 className="card-text text-success">
                                {qtySales}
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Ultimo sorteio caixa:</h4>
                            <h5 className="card-text text-success">
                                10/10/2024
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {qtySaleByDays.length > 0 && (
                    <div className="col-md-12 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <Chart
                                    chartType="Bar"
                                    width="100%"
                                    height="400px"
                                    data={qtySaleByDays}
                                    options={optionsQtySaleByDays}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className="col-md-12 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Rifas em andamento:</h4>
                            <div>
                                <TableView headers={headersRaffles} rows={rafflesPending} total={rafflesPending.length} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ dashboard: { qtySaleByDays, totalSales, qtySales, qtyRafflesPending, qtyRafflesFinished, totalAllLife, rafflesPending } }) => ({
    qtySaleByDays,
    totalSales,
    qtySales,
    qtyRafflesPending,
    qtyRafflesFinished,
    totalAllLife,
    rafflesPending
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callDashboardViewGet
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)