import { connect } from 'react-redux'
import { Route, Routes } from 'react-router'
import OrderList from './OrderList'
import OrderView from './OrderView'
import { useState } from 'react'
import Sidebar from '../../../../../common/containers/Sidebar'
import Card from '../../../../../common/containers/Card'

let OrderPage = () => {
    const [pageAttr, setPageAttr] = useState({})

    return (
        <Sidebar pWidth='90%'>
            <Card {...pageAttr} closeTo='/'>
                <Routes>
                    <Route path='/view/:id' element={<OrderView setPageAttr={setPageAttr} />} />
                    <Route index element={<OrderList setPageAttr={setPageAttr} />} />
                </Routes>
            </Card>
        </Sidebar>
    )
}

export default connect()(OrderPage)