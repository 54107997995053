import { connect } from 'react-redux'
import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { callOrderListGet, callOrderClearView } from '../../actions/orders'
import { Link, useSearchParams } from 'react-router-dom'
import TableView from '../../../../../common/containers/TableView'
import moment from 'moment'

let BRL = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

let OrderList = ({ setPageAttr, list, methods: { callOrderListGet, callOrderClearView } }) => {
    let [searchParams] = useSearchParams();
    
    const headers = [
        {
            type: 'info',
            name: 'id',
            align: 'center',
            label: 'ID'
        },
        {
            type: 'custom',
            align: 'center',
            label: 'Data',
            custom: (a) => {
                return <>{moment(a.created_at).format('DD/MM/YYYY HH:mm')}</>
            }
        },
        {
            type: 'info',
            name: 'product_name',
            align: 'left',
            label: 'Rifa'
        },
        {
            type: 'info',
            name: 'customer_name',
            align: 'left',
            label: 'Cliente'
        },
        {
            type: 'info',
            name: 'qty',
            align: 'center',
            label: 'Qtde Núm.'
        },
        {
            type: 'custom',
            align: 'center',
            label: 'Status',
            custom: (a) => {
                switch(a.status){
                    case 'paid':
                        return <span className='btn btn-success btn-sm'>PAGO</span>
                    case 'pending':
                        return <span className='btn btn-warning btn-sm'>PENDENTE</span>
                    case 'expired':
                        return <span className='btn btn-danger btn-sm'>EXPIRADO</span>
                    default:
                        return <>-</>
                }
            }
        },
        {
            type: 'custom',
            align: 'center',
            label: 'Pagamento',
            custom: (a) => {
                switch(a.payment_method){
                    case 'pix':
                        return <>PIX</>
                    case 'credit':
                        return <>Cartão de Crédito</>
                    default:
                        return <>-</>
                }
            }
        },
        {
            type: 'custom',
            align: 'center',
            label: 'Total(R$)',
            custom: (a) => {
                return <>{BRL.format(a.total)}</>
            }
        },
        {
            type: 'custom',
            align: 'right',
            label: 'Opções',
            custom: (a) => {
                return (
                    <>
                        <Link to={'/orders/view/' + a.id} className='btn btn-sm btn-primary'><i className='fas fa-eye'></i></Link>
                    </>
                )
            }
        }
    ]

    useEffect(() => {
        callOrderClearView()
        let pg = searchParams.get('pg')
        pg = pg ? pg : 1
        callOrderListGet({}, pg)
    }, [callOrderListGet, callOrderClearView, searchParams])

    useEffect(() => {
        setPageAttr({
            title: 'Pedidos',
            caption: 'Gestão dos pedidos dos clientes',
            btns: [],
            tabs: [
                {
                    active: true,
                    to: '/orders',
                    label: 'Informações'
                }
            ]
        })
    }, [setPageAttr])

    return (
        <TableView headers={headers} rows={list.rows} total={list.total} pagination={list.pagination} paginationTo={'/orders'} />
    )
}

const mapStateToProps = ({ sale: { orders: { list } } }) => ({
    list: list,
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callOrderListGet,
            callOrderClearView
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderList)