import { toast } from "react-toastify";
import Api from '../../../../axios'

let ACTION_ORDER_LIST = {
    type: 'ORDER_LIST',
    payload: {
        rows: [],
        total: 0,
        pagination: [],
    },
};

let ACTION_ORDER_VIEW = {
    type: 'ORDER_VIEW',
    payload: {
        id: '',
        created_at: '',
        product_name: '',
        customer_name: '',
        qty: '',
        total: '',
        payment_method: '',
        status: ''
    },
};

let callOrderListGet = (filter = {}, pg = 1) => (dispatch) => {
    const loader = toast.loading("Buscando registros...")
    Api.get('/orders?page=' + pg).then((data) => {
        console.log(data)
        if (!data) {
            toast.dismiss(loader)
            return
        }

        toast.dismiss(loader)
        ACTION_ORDER_LIST.payload.rows = data.rows
        ACTION_ORDER_LIST.payload.total = data.total
        ACTION_ORDER_LIST.payload.pagination = data.pagination
        dispatch(ACTION_ORDER_LIST)
    })
}

let callOrderViewGet = (id) => (dispatch) => {
    const loader = toast.loading("Buscando detalhes do registro...")
    Api.get('/orders/' + id).then((data) => {
        if (!data) {
            toast.dismiss(loader)
            return
        }

        toast.dismiss(loader)
        ACTION_ORDER_VIEW.payload.id = data.id
        ACTION_ORDER_VIEW.payload.created_at = data.created_at
        ACTION_ORDER_VIEW.payload.product_name = data.product_name
        ACTION_ORDER_VIEW.payload.customer_name = data.customer_name
        ACTION_ORDER_VIEW.payload.qty = data.qty
        ACTION_ORDER_VIEW.payload.total = data.total
        ACTION_ORDER_VIEW.payload.payment_method = data.payment_method
        ACTION_ORDER_VIEW.payload.status = data.status
        dispatch(ACTION_ORDER_VIEW)
    })
}

let callOrderClearView = () => (dispatch) => {
    ACTION_ORDER_VIEW.payload.id = ''
    dispatch(ACTION_ORDER_VIEW)
}

export { callOrderListGet, callOrderViewGet, callOrderClearView, ACTION_ORDER_LIST, ACTION_ORDER_VIEW }